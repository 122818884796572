<template>
    <ion-item :router-link="`/tabs/users/${user.id}`">
            <ion-thumbnail slot="start">
                <ion-img :src="user.photoURL" :alt="user.name"></ion-img>
            </ion-thumbnail>
            <ion-label>
                {{user.name}}
            </ion-label>
        </ion-item>
</template>

<script>
//import { IonItem, IonThumbnail, IonImg, IonLabel } from '@ionic/vue';

export default {
    props: ["user"],
    components: {
        // IonItem, 
        // IonThumbnail, 
        // IonImg, 
        // IonLabel
    }
}
</script>