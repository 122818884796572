<template>
  <base-layout title="Users" page-default-back-link="/tabs/store">
    <ion-list>
          <user-list-item 
              v-for="user in users" 
              :key="user.id"
              :user="user"
              >
          </user-list-item>
      </ion-list>
  </base-layout>
</template>

<script>
//import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';
//import { IonList } from '@ionic/vue';
import UserListItem from '../components/UserListItem.vue';
export default  {
  components: { UserListItem, },
  data() {
    return {
      
    };
  },
  computed: {
    users() {
      return this.$store.getters.users;
    }
  },
  mounted() {
    if (this.users == null) {
      this.$store.dispatch("loadUsers");
    }
  }
}
</script>